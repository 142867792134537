import React from "react"
import ArticleContentBody from "../../components/pages/ArticleDetails/ArticleContentBody"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const SettingUpTaxProfile = () => {
  return (
    <Layout>
      <Seo
        title="Setting Up Tax Profile"
        description={`Learn how to set up and update your tax profile in the Synkli mobile app. Follow simple steps to login, access settings, edit, and save your financial details accurately.`}
      />

      {/* This slug is matched with articles in Content */}
      <ArticleContentBody slug={`setting-up-tax-profile`} />
    </Layout>
  )
}

export default SettingUpTaxProfile
